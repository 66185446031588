<template>
    <div class="about-container">
        <div class="content">
            <div>
                <span style="font-size: 40px; color: white;">01</span> <span>首页展示</span>
            </div>
            <div class="product">
                <img class="about-banner-img" src="../images/product1.png" style="width: 200px;" alt="">
                <div>
                    <div class="text">1.页面简洁易用，持续更新乡村短视频，展示了农村风光、生活和文化特色</div>
                    <div class="text">2.用户评论，增加的互动性，分享到其他社交平台，内容的曝光量和传播效果</div>
                    <div class="text">3.关注和粉丝：可以关注喜欢的用户，也可以被其他用户关注，还可以查看自己的粉丝列表。</div>
                    <div class="text">4.可以进入作者的乡村，逛逛不一样的乡村生活。</div>
                    <div class="text">
                        5.用户随时随地都能够观看乡村的变化，了解最近的农业动态和农村生活方式，在这里，用户能够看到农民的勤劳和智慧，感受到农村的美好和魅力。无论身在何处，只要打开这款应用程序，就可以让用户在自己的手机屏幕上畅游于乡村风光之间，掌握最新的农业信息和技术，同时也可以交流互动、分享乐趣、增加彼此的了解。不仅丰富了农村居民的精神生活，更是推广和传承乡土文化的重要平台之一。
                    </div>
                </div>
            </div>

            <div>
                <span style="font-size: 40px; color: white;">02</span> <span>朋友页面展示</span>
            </div>
            <div class="product">
                <img class="about-banner-img" src="../images/product2.png" style="width: 200px;" alt="">
                <div>
                    <div class="text">
                        在这里，用户可以通过“关注好友”功能，关注自己感兴趣的其他用户，建立起自己的人脉圈子。当用户关注了某个好友后，就可以在自己的朋友动态中看到该好友发布的最新短视频，并对其进行点赞、评论、分享等互动操作，进一步加深和拓展双方的社交关系。同时，好友之间也可以互相关注，建立起更加紧密和深入的社交网络，不仅增强了用户的交流和互动体验，更能够为平台带来更多的用户活跃度，推动平台的长期稳定发展。
                    </div>
                </div>
            </div>

            <div>
                <span style="font-size: 40px; color: white;">03</span> <span>消息中心展示</span>
            </div>
            <div class="product">
                <img class="about-banner-img" src="../images/product3.png" style="width: 200px;" alt="">
                <div>
                    <div class="text">
                        用户可以通过“消息中心”功能，及时获取自己的最新消息和通知。当用户收到点赞、评论、关注、私信等相关操作时，都可以在消息中心里进行查看和处理，方便用户及时掌握和回应其他用户的互动信息。同时，平台也会通过消息中心，向用户推送一些个性化的推荐内容和活动信息，帮助用户更好地发现和分享有趣的短视频内容。通过消息中心这个集中管理和展示消息的界面，用户可以更加方便地管理和处理各类通知和信息，提高了用户的使用效率和体验质量，增强了平台的互动性和社交属性。
                    </div>
                </div>
            </div>

            <div>
                <span style="font-size: 40px; color: white;">04</span> <span>我的页面展示</span>
            </div>
            <div class="product">
                <img class="about-banner-img" src="../images/product4.png" style="width: 200px;" alt="">
                <div>
                    <div class="text">
                        在这里，让用户能够更方便地管理和展示自己的个人信息和短视频内容。用户可以编辑和设置自己的基本信息，进一步展示自己的创作才华和社交魅力。同时，个人中心还为用户提供了一系列的设置项和功能，如账号安全、隐私设置、通用设置等，让用户有更多的个性化自定义选项和操作空间。通过个人中心这个集中展示和管理个人信息和作品的界面，用户能够更方便地展示自己的独特风格和个人魅力，增强了用户的使用体验和社交价值。
                    </div>
                </div>
            </div>
            
            <div class="summarize">

            </div>
        </div>
        <footer id="end">
            <div class="container">
                <div class="container-child">
                    <div class="flex contact">
                        <div>联系我们</div>
                        <div> <i class="iconfont icon-service-phone"></i> 全国热线：400-119-0056</div>
                        <div><i style="margin-right: 2px;"
                                class="iconfont icon-dizhi"></i>地&ensp;&ensp;&ensp;&ensp;址：合肥市包河区青年电商园6栋301</div>
                    </div>
                    <div class="">
                        <div class="download">安卓下载：逢集-乡村</div>
                        <div>
                            <img class="about-banner-img" src="../images/scan.jpg" style="width: 80px;" alt="">
                        </div>
                      
                    </div>
                </div>

                <span class="company">
                    版权所有 © 2023 All Rights Reserved 安徽极智云网络科技有限公司 版权所有ICP证 <a href="https://beian.miit.gov.cn/"
                        target="_blank" style="color: #b6b7b9;">皖B2-20170077</a>
                </span>
            </div>
        </footer>
    </div>
</template>
        
<script>
// import axios from "axios";
export default {
    name: "ProductComponet",
    data() {
        return {
            searchStr: "",
        };
    },
    methods: {

    },
};
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about-container {
    display: flex;
    flex-direction: column;
    /* height:calc(100vh - 150px); */
    padding-top: 150px;
}

.about-banner {}

.about-banner-img {
    width: 90vw;
    margin: 0 5vw;
    border-radius: 10px;
}

.content {
    padding: 20px 50px;
    flex-grow: 1;
    /* color: rgb(230, 230, 230); */
    font-size: 1.3em;
    line-height: 2em;
    font-family: 'KaiTi_GB2312', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

p {
    text-indent: 2em;
    margin-top: 15px;
}

.flex {
    display: flex;
    flex-direction: column;
}

.contact {
    margin-top: 20px;
    padding-left: 20px;
}

.product {
    display: flex;
    padding: 20px;
}

.text {
    color: #999;
    font-size: 16px;
}

.container-child {
    display: flex;
    justify-content: space-between;
}
.download{
    text-align: center;
    padding: 10px;
}
.contact div{
    padding-top: 10px;
}
</style>
        
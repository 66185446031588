import Vue from 'vue';
import App from './App.vue';


import { Carousel,CarouselItem, Pagination,Message} from 'element-ui';
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.prototype.$message = Message;

import router from './js/router.js';
import VueRouter from 'vue-router';

Vue.use(VueRouter)


new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
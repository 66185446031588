<template>
    <div class="village-container">
      <div class="village-content">
        <img class="village-qidai" src="../images/3.jpeg" alt="">
      </div>
      <footer id="end">
            <div class="container">
                <span class="company">
                    版权所有 © 2023 All Rights Reserved 安徽极智云网络科技有限公司 版权所有ICP证 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #b6b7b9;">皖B2-20170077</a>
                </span>
            </div>
        </footer>
    </div>
  </template>
        
    <script>
    // import axios from "axios";
    export default {
    name: "LiveComponet",
    data() {
      return {
        searchStr: "",
      };
    },
    methods: {

    },
  };
  </script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.village-container{
  background: rgb(246, 246, 246);
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* justify-content: center; */
  /* padding-top: 130px; */
}
.village-content{
  flex-grow: 1;
  
  /* height: calc(100vh - 90px); */
  /* background: rgb(246, 246, 246); */
}
.village-qidai{

   margin-top:30vh; 
   width: 60vw;
   height: auto;
   margin-left:20vw;
    /* object-fit: cover; */
    
}
  </style>
        
<template>
  <div class="home-container">
    <!--banner开始-->
    <div style="height: 120px;"></div>
    <li class="item-li">
      <img class="video-img" :src="village.video_image" alt="">
      <h2 class="item-name">{{ village.name }}</h2>
      <p class="item-desc">{{ village.desc }}</p>
    </li>
    <div id="video-section" class="con_area">
      <div class="container">
        <div class="recent_con">
          <div class="con_top">
            <h1>
              <i class="iconfont icon-shipin"></i>
              最新动态
            </h1>
          </div>
          <div class="waterfall">
            <ul class="item" v-for="(item, index) in list" :key="index">
              <li class="item-li ">
                <div @click="handlePlay(index, item.player_id)" class="play-div">
                  <video  :src="item.url" webkit-playsinline="true" playsinline="true"
                    x-webkit-airplay="allow" x5-playsinline style="width: 100%;" ref="video" :poster="item.main_image"
                    :id="item.player_id" @ended="handleVideoEnded(index)">
                  </video>
                  <a v-if="!item.playing">
                    <i class="iconfont icon-bofang1 play-icon"></i>
                  </a>
                </div>
                <div class="video-content">
                  {{ item.desc }}
                </div>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
    <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="last_page * 10">
    </el-pagination>
    <footer id="end">
      <div class="container">
        <span class="company">
          版权所有 © 2023 All Rights Reserved 安徽极智云网络科技有限公司 版权所有ICP证 <a href="https://beian.miit.gov.cn/" target="_blank"
            style="color: #b6b7b9;">皖B2-20170077</a>
        </span>
      </div>
    </footer>
  </div>
</template>
        
<script>
import axios from 'axios'
export default {
  name: "VillageVideo",
  mounted() {
 
    this.village = this.$route.query.item;
    console.log("====",this.village);
    this.postVideoList();
  },

  methods: {
    handleVideoEnded(index){
      this.list[index].playing = false;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.last_player_id = '';
      this.last_player_index = '';
      this.postVideoList();
      window.scrollTo(0, 500);
    },
    handlePlay(index, player_id) {
      console.log(this.last_player_id, player_id);

      var video = document.getElementById(player_id);
      if (this.last_player_id == player_id) {
        if (this.list[index].playing) {
          video.pause();
          console.log("关闭", this.last_player_id);
        } else {
          video.play();
          console.log("播放1", index);
        }
        this.list[index].playing = !this.list[index].playing;
      } else {
        //先关闭之前的
        if (this.last_player_id !== '') {
          console.log("关闭", this.last_player_id);
          var last_video = document.getElementById(this.last_player_id);
          this.list[this.last_player_index].playing = false;
          last_video.pause();
        }
        this.list[index].playing = true;
        video.play();
        console.log("播放2", index);
      }
      this.last_player_id = player_id;
      this.last_player_index = index;
      // console.log("====",this.list);
    },

    postVideoList() {
      var that = this;
      // 发送POST请求
      axios.post('https://v-video.56yidi.com/h5/v1/video/villageVideos', { village_id: this.$route.query.id, page: this.page })
        .then(response => {
          that.last_page = response.data.data.last_page;
          var arr = response.data.data.data;
          for (var index = 0; index < arr.length; index++) {
            var obj = arr[index];
            obj.player_id = "banner_player_" + index;
            obj.playing = false;

          }
          that.list = arr;
          console.log(response.data.data.data, that.list);
        })
        .catch(error => {
          console.log("0====", error)
        })
    }
  },
  data() {
    return {
      page: 1,
      player: null,
      villageList: [],
      last_page: 1,
      list: [],
      last_player_id: '',
      last_player_index: '',
      village:{}
    }
  },
};
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

}


.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.el-pagination {
    text-align: center;
}

.el-carousel__item {
    height: 500px;
}

.video-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
}

.item-li {
    position: relative;
    /* overflow: hidden; */
}

.play-icon {
    position: absolute;
    top: calc(50% - 12px);
    left:  calc(50% - 12px);
}

.play-div {
    position: relative;
}

.video-content {
    font-size: 1em;
    color: white;
    /* height: 40px; */
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    line-height: 20px;
    text-align: left;
    /* margin: 10px 5px; */
    padding: 10px 5px 0 5px;
}


.home-banner {
    height: 500px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
    border-radius: 10px;
    /* padding-top: 120px; */
}

.banner {
    width: 100%;
    /* 图片宽度，避免拉伸变形 */
    height: 500px;
    /* 图片高度 */

}

.logo-child {
    width: 110px;
    height: 50px;
}

.waterfall {
    padding: 15px;
    margin-top: 20px;
    column-count: 4;
    /* 将容器分成 3 列 */
    column-gap: 20px;
    /* 列与列之间的间距 */
}

.item {
    break-inside: avoid;
    /* 防止子元素在容器边界处被切断 */
    margin-bottom: 30px;
    /* 子元素之间的间距 */
    padding: 15px;
    background-color: rgb(37, 38, 50);
    color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.home-container {
    width: 100%;
    height: 100%;
}

.item-li {
    position: relative;
}

.video-img {
    width: 90vw;
    margin: 5vw;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    /* height: auto; */
}

.item-name {
    position: absolute;
    top: 45%;
    left: 10vw;
    width: 80vw;
    font-size: 2em;
}

.item-desc {
    position: absolute;
    top: 55%;
    left: 10vw;
    color: white;
    font-size:1em;
    width: 80vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;

}
</style>
        
<template>
  <div id="app">
    <!--头部开始	-->
    <header v-if="showHeader" class="header">
      <div class="container">
   <div class="certificate-box">
    <div class="certificate">网络文化经营许可证 皖网文(2023)2385-035号</div>
          <div class="certificate">广播电视节目制作经营(皖)字第01205号</div>
   </div>
        <div class="header_area">
          <div class="logo">
            <a href="index.html">
              <img class="logo-child" src="./images/logo2.png" alt="logo" />
            </a>
          </div>

          <div class="meau">
            <ul>
              <router-link to="/login">
                <li>
                  <a>{{ loginInfo?"个人中心":"登录/注册" }} </a>
                </li>
              </router-link>
              <router-link to="/product">
                <li>
                  <a>产品介绍</a>
                </li>
              </router-link>
              <router-link to="/about">
                <li>
                  <a href="about.html">关于我们</a>
                </li>
              </router-link>
              <router-link to="/live">
                <li>
                  <a href="live.html">直播</a>
                </li>
              </router-link>
              <router-link to="/village">
                <li>
                  <a>乡村</a>
                </li>
              </router-link>
              <router-link to="/">
                <li>
                  <a>首页</a>
                </li>
              </router-link>

            </ul>
          </div>
        </div>
      </div>
      <!--头部结束-->
    </header>
    <router-view v-on:header="header"></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  mounted() {
    console.log("1----");
    this.loginInfo = JSON.parse(localStorage.getItem('LOGININFO'));
    // console.log('loginInfo====', this.loginInfo.nickname);
  },
  beforeUpdate(){
    console.log("2----");
    this.loginInfo = JSON.parse(localStorage.getItem('LOGININFO'));
  },
  methods: {
    header:function (bool) {
      console.log(bool)
        this.showHeader = bool;
      },
  },
  data() {
    return {
      page: 1,
      player: null,
      villageList: [],
      total: 1,
      list: [],
      loginInfo:{},
      nickname:"",
      showHeader:true

    }
  },
}
</script>

<style>
@import "./css/main.css";
@import "./css/style.css";
@import "./css/iconfont.css";
@import "./css/mian_style.css";

.logo-child {
  width: 400px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 30px;
}

.certificate{
  width: 470px;
  height: 20px;
}

.certificate-box{
  position: absolute;
  left: 400px;
  top: 30px;
  height: 600px;
  height: 30px;
}
</style>

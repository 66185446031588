<template>
    <div class="login-container">
        <div class="login-content" v-if="!logined">
            <div class="top-title">
                <div>
                    您好！欢迎来到
                </div>
                <div>
                    安徽极智云网络科技有限公司
                </div>
            </div>
            <div class="section-header">姓名</div>
            <input class="name input-class" maxlength="6" type="text" v-model="nameInputValue">
            <div class="section-header">手机号</div>
            <input class="phone input-class" maxlength="11" type="text" step="any" v-model="phoneInputValue">
            <div class="section-header">验证码</div>
            <div class="code-container">
                <input class="code input-class" maxlength="4" type="text" step="any" v-model="codeInputValue">
                <button @click="codeEvent" class="code-btn" :disabled="countdown > 0">{{ countdown > 0 ?
                    `${countdown}s` : '验证码' }}</button>
            </div>
            <button @click="loginEvent" class="login-btn">登录</button>
            <div class="xieyi-container"><img class="agree-image" src="../images/agree2.png" alt="">已阅读并同意<button class="xieyi" @click="xieyiEvent">《用户协议》</button> 和 <button class="xieyi"
                    @click="yinsiEvent">《隐私政策》</button></div>
        </div>
        <div class="login-content" v-if="logined">
            <div class="top-title">
                <div>
                    您好！欢迎来到
                </div>
                <div>
                    安徽极智云网络科技有限公司
                </div>
            </div>
            <div class="info">
                <img class="info-image" :src="loginInfo.avatar" alt="">
                <div class="info-name">{{ loginInfo.nickname }}</div>
            </div>
            <button @click="loginOutEvent" class="login-btn">退出</button>
            <div class="app-download">
                <img class="app-img" src="../images/scan.jpg" style="width: 80px;" alt="">
                <div>
                    <div class="app-dec">
                        体验更全面的功能
                    </div>
                    <div class="app-dec">请下载我们的安卓版‘逢集’APK</div>
                </div>
            </div>
        </div>
        <div v-if="showPop">
            <div  class="pop-xieyi" v-html="htmlContent"></div>
            <img @click="closeEvent" class="close" src="../images/叉.png" alt="">
        </div>
     
        <!-- <footer id="end">
            <div class="container">
                <span class="company">
                    版权所有 © 2023 All Rights Reserved 安徽极智云网络科技有限公司 版权所有ICP证 <a href="https://beian.miit.gov.cn/"
                        target="_blank" style="color: #b6b7b9;">皖B2-20170077</a>
                </span>
            </div>
        </footer> -->
    </div>
</template>
        
<script>
import axios from "axios";


export default {
    name: "LoginComponet",
    data() {
        return {
            searchStr: "",
            countdown: 0,
            timerId: null,
            nameInputValue: "",
            phoneInputValue: "",
            codeInputValue: "",
            loginInfo: "",
            logined: false,
            showPop: false,
            htmlContent: '',
            agreeImage:'../images/agree1.png'
        };
    },
    mounted() {
        console.log("----");
        this.loginInfo = JSON.parse(localStorage.getItem("LOGININFO"));
        if (this.loginInfo) {
            this.logined = true;
        }
        else {
            this.logined = false;
        }
    },
    methods: {
        closeEvent() {
            this.showPop = false;
        },
        xieyiEvent() {

            this.showPop = true
            console.log('====2');
            var that = this;
            // 发送POST请求
            axios.post("https://v-video.56yidi.com/v1/common/news", {
                type: 8,
            })
                .then(response => {
                    if (response.data.code == 1) {
                        that.htmlContent = response.data.data.content;
                    }

                    console.log(response.data.data.content);
                })
                .catch(error => {
                    console.log("0====", error);
                    // this.$message.error(error);
                });
        },
        yinsiEvent() {
          
            this.showPop = true
            console.log('====2');
            var that = this;
            // 发送POST请求
            axios.post("https://v-video.56yidi.com/v1/common/news", {
                type: 9,
            })
                .then(response => {
                    if (response.data.code == 1) {
                        that.htmlContent = response.data.data.content;
                    }

                    console.log(response.data.data.content);
                })
                .catch(error => {
                    console.log("0====", error);
                    // this.$message.error(error);
                });
        },
        startCountdown() {
            this.countdown = 60; // 设置倒计时初始值为60秒
            this.timerId = setInterval(() => {
                this.countdown--; // 每一秒倒计时减1
                if (this.countdown <= 0) {
                    clearInterval(this.timerId); // 倒计时结束，清除计时器
                }
            }, 1000);
        },
        codeEvent() {
            if (this.phoneInputValue.length != 11) {
                this.$message({
                    message: "请输入正确的手机号",
                    type: "warning"
                });
                return;
            }
            this.sendCodeRequest();
            this.startCountdown(); // 发送验证码后开始倒计时  
            console.log("codeEvent");
        },
        loginEvent() {
            console.log("loginEvent");
            if (this.phoneInputValue.length != 11 || this.nameInputValue == "" || this.codeInputValue.length != 4) {
                this.$message({
                    message: "请正确填写完整信息",
                    type: "warning"
                });
                return;
            }
            this.loginRequest();
        },
        loginOutEvent() {
            localStorage.removeItem("LOGININFO");
            this.$router.push("/");
        },
        loginRequest() {
            var that = this;
            // 发送POST请求
            axios.post("https://v-video.56yidi.com/v1/common/mobilelogin", {
                mobile: that.phoneInputValue,
                name: that.nameInputValue,
                captcha: that.codeInputValue,
            })
                .then(response => {
                    if (response.data.code == 1) {
                        console.log(response.data.data.userinfo);
                        localStorage.setItem("LOGININFO", JSON.stringify(response.data.data.userinfo));
                        this.$router.push("/");
                    }
                    else {
                        this.$message({
                            message: response.data.msg,
                            type: "warning"
                        });
                    }
                })
                .catch(error => {
                    console.log("0====", error);
                    // this.$message.error(error);
                });
        },
        sendCodeRequest() {
            var that = this;
            // 发送POST请求
            axios.post("https://v-video.56yidi.com/v1/common/sendSms", {
                mobile: that.phoneInputValue,
                event: "mobilelogin"
            })
                .then(response => {
                    if (response.data.code == 1) {
                        this.$message({
                            message: response.data.msg,
                            type: "success"
                        });
                    }
                    else {
                        this.$message({
                            message: response.data.msg,
                            type: "warning"
                        });
                    }
                    console.log(response.data.data);
                })
                .catch(error => {
                    console.log("0====", error);
                    // this.$message.error(error);
                });
        }
    },
    beforeDestroy() {
        clearInterval(this.timerId); // 组件销毁前清除计时器
    },

};
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-container {
    background: rgb(19, 21, 30);
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.login-content {
    width: 460px;
    height: 600px;
    border-radius: 20px;
    background: rgb(240, 240, 240);
    position: absolute;
    left: calc(50% - 250px);
    top: calc(50% - 300px);
}

.top-title {
    margin-left: 70px;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px;
    color: rgb(100, 168, 240);
    line-height: 30px;
}

.input-class {
    margin-left: 70px;
    width: 300px;
    height: 30px;
    border-radius: 5px;
    background: white;
    margin-top: 10px;
    color: black;
}

.section-header {
    font-size: 14px;
    color: rgb(180, 180, 180);
    margin-left: 70px;
    margin-top: 20px;
}

.code {
    width: 40% !important;
}

.code-btn {
    width: 80px;
    height: 40px;
    border-radius: 5px;
    background: rgb(100, 168, 240);
    color: white;
    text-align: center;
    line-height: 40px;
    margin-top: 12px;
    margin-left: 20px;
}

.code-container {
    display: flex;
}

.login-btn {
    width: 300px;
    height: 50px;
    border-radius: 5px;
    background: rgb(100, 168, 240);
    color: white;
    text-align: center;
    line-height: 50px;
    margin-top: 40px;
    margin-left: 80px;
}

button {
    border: none;
    /* 去掉边框 */
    color: #fff;
    /* 按钮字体颜色 */
    box-shadow: none;
    /* 去掉阴影 */
    -webkit-box-shadow: none;
}

.info {
    color: black;
    text-align: center;
}

.info-image {
    border: 1px solid rgb(100, 168, 240);
    border-radius: 50%;
    margin-top: 30px;
}

.info-name {
    margin-top: 30px;
}

.app-img {
    width: 90px;
    margin: 0 10px 0 80px;
    border-radius: 10px;
}

.app-download {
    display: flex;
    margin-top: 30px;
}

.app-dec {
    line-height: 35px;
}

.xieyi {
    font-size: 12px;
    color: rgb(68, 109, 165);

}

.xieyi-container {
    font-size: 12px;
    margin-top: 10px;
    margin-left: 80px;
}

.pop-xieyi {
    position: absolute;
    width: calc(100% - 40px);
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    z-index: 1;
    padding: 20px;
    margin-top: 100px;
}
.close{
    position: fixed;
    right: 20px;
    top: 120px;
    width: 30px;
    height: 30px;
    z-index: 2;
}
.agree-image{
    width: 14px;
    height: 14px;
    margin-right: 5px;
    line-height: 12px;
    margin-bottom: -2px;
}
</style>
        
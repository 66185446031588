//引入vue
import Vue from 'vue';
//引入vue-router
import VueRouter from 'vue-router';
//第三方库需要use一下才能用
Vue.use(VueRouter)
//引用组件页面
import Home  from '../components/Home.vue';
import Village from '../components/Village.vue';
import Live from '../components/Live.vue';
import About from '../components/About.vue';
import Product from '../components/Product.vue';
import villagevideo from '../components/VillageVideo.vue';
import Login from '../components/Login.vue';
import Privacy from '../components/Privacy.vue';
import Yinsi from '../components/Yinsi.vue';

//定义routes路由的集合，数组类型
const routes=[
    {path:'/',name:'HomeComponet',component:Home},
    {path:"/village",name:'VillageComponet',component:Village},
    {path:"/live",name:'LiveComponet',component:Live},
    {path:"/about",name:'AboutComponet',component:About},
    {path:"/product",name:'ProductComponet',component:Product},
    {path:"/villagevideo",name:'VillageVideo',component:villagevideo},
    {path:"/login",name:'LoginComponet',component:Login},
    {path:"/privacy",name:'PrivacyComponet',component:Privacy},
    {path:"/yinsi",name:'YinsiComponet',component:Yinsi},
    
]
 
//实例化 VueRouter 并将 routes 添加进去
const router = new VueRouter({
    //ES6简写，等于routes：routes
    routes
});
 
//抛出这个这个实例对象方便外部读取以及访问
export default router
<template>
    <div class="about-container">
        <div v-html="htmlContent"></div>
    </div>
</template>
        
<script>
import axios from "axios";
export default {
    name: "PrivacyComponet",
    mounted() {
        console.log("----");
        this.getPrivacy();
    },
    data() {
        return {
            htmlContent:''
        };
    },
    methods: {
        getPrivacy() {
            // var that = this;
            // 发送POST请求
            axios.get('https://v-video.56yidi.com/v1/common/news?type=2', {})
            .then(response => {
                    console.log("villageList===", response.data.data);
                    this.htmlContent = response.data.data.content;
            })
            .catch(error => {
                console.log("0====", error)
            })
        },
       
    },
};
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
        
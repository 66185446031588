<template>
    <div class="about-container">
        <div>
            <div class="pop-xieyi" v-html="htmlContent"></div>
        </div>
    </div>
</template>
        
<script>
import axios from "axios";
export default {
    name: "YinsiComponet",
    data() {
        return {
            htmlContent: ""
        };
    },
    created:function () {
            this.$emit('header', false);
        },
    mounted() {
        this.yinsiEvent()
    },
    methods: {
        yinsiEvent() {
            console.log('====2');
            var that = this;
            // 发送POST请求
            axios.post("https://v-video.56yidi.com/v1/common/news", {
                type: 2,
            })
                .then(response => {
                    if (response.data.code == 1) {
                        that.htmlContent = response.data.data.content;
                    }
                })
                .catch(error => {
                    console.log("0====", error);
                    // this.$message.error(error);
                });
        },
    },
};
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
<template>
    <div class="home-container">
        <!--banner开始-->
        <div style="height: 120px;"></div>
        <el-carousel trigger="click" :interval="5000" @change="bannerChange" class="home-banner" height="40vw"
            :autoplay="bannerAuto">
            <el-carousel-item @click.native="bannerClick(item, index)" v-for="(item, index) in villageList" :key="index">
                <!-- <img class="banner" :src="item.video_image"> -->
                <video :src="item.video" webkit-playsinline="true" playsinline="true" x-webkit-airplay="allow"
                    x5-playsinline style="width: 100%;" ref="video" :poster="item.video_image" :id="item.player_id"
                    @ended="bannerVideoEnded(index)">
                </video>
                <a v-if="!item.playing">
                    <i style="font-size: 3vw;" class="iconfont icon-bofang1 play-icon"></i>
                </a>
            </el-carousel-item>
        </el-carousel>
        <div>

            <h2 class="product-title">乡村振兴的一大助力</h2>
            <p class="product-text">乡村特产、特色、景光、文化、传承、日常，乡村是我们共同的故乡。</p>

        </div>
        <div class="product-dsc">
            <img class="product-img" src="../images/p_1.jpeg" alt="">
            <img class="product-img" src="../images/p_2.jpeg" alt="">
            <img class="product-img" src="../images/p_3.jpeg" alt="">
            <img class="product-img" src="../images/p_4.jpeg" alt="">
        </div>
        <div class="product-title">安卓扫码下载，立即体验</div>
        <img class="product-scan" src="../images/scan.jpg" alt="">
        <div class="con_area">
            <div class="container">
                <div class="recent_con">
                    <div class="con_top">
                        <h1>
                            <i class="iconfont icon-shipin"></i>
                            最新动态
                        </h1>
                    </div>
                    <div id="video-section" class="waterfall">
                        <ul class="item" v-for="(item, index) in list" :key="index">
                            <li class="item-li ">
                                <div @click="handlePlay(index, item.player_id)" class="play-div">
                                    <video class="video-img" :src="item.url" webkit-playsinline="true" playsinline="true"
                                        x-webkit-airplay="allow" x5-playsinline style="width: 100%;" ref="video"
                                        :poster="item.main_image" :id="item.player_id" @ended="handleVideoEnded(index)">
                                    </video>
                                    <a v-if="!item.playing">
                                        <i class="iconfont icon-bofang1 play-icon"></i>
                                    </a>
                                </div>
                                <div class="video-content">
                                    {{ item.desc }}
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
        <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="last_page * 10">
        </el-pagination>
        <footer id="end">
            <div class="container">
                <span class="company">
                    版权所有 © 2023 All Rights Reserved 安徽极智云网络科技有限公司 版权所有ICP证 <a href="https://beian.miit.gov.cn/"
                        target="_blank" style="color: #939599;">皖B2-20170077</a>
                </span>
                <div class="gwab"> <img class="gongan-image" src="../images/gongan.png" alt=""> <a
                        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34011102003411">皖公网安备
                        34011102003411号</a>   网络文化经营许可证 皖网文(2023)2385-035号   广播电视节目制作经营(皖)字第01205号</div>
            </div>
            <div class="complaintTelephone">
                文化市场投诉举报电话 12318 
            </div>
        </footer>
    </div>
</template>
        
<script>
import axios from 'axios'
export default {
    name: "HomeComponet",
    mounted() {
        console.log("----");
        this.postVideoList();
        this.postVillageList();
    },
    methods: {
        bannerVideoEnded(index) {
            this.villageList[index].playing = false;
        },
        bannerChange() {
            if (this.banner_last_player_id != '') {
                var last_video = document.getElementById(this.banner_last_player_id);
                this.villageList[this.banner_last_player_index].playing = false;
                last_video.pause();
            }

        },
        bannerClick(item, index) {
            //先关闭列表的播放
            if (this.last_player_index != -1) {
                if (this.list[this.last_player_index].playing) {
                    this.list[this.last_player_index].playing = false;
                    var listvideo = document.getElementById(this.last_player_id);
                    listvideo.pause();
                }
            }


            this.bannerAuto = false;
            var player_id = item.player_id;
            console.log("banner==", index);
            var video = document.getElementById(player_id);
            if (this.banner_last_player_id == player_id) {
                if (this.villageList[index].playing) {
                    video.pause();
                    console.log("关闭", this.banner_last_player_id);
                } else {
                    video.play();
                    console.log("播放1", index);
                }
                this.villageList[index].playing = !this.villageList[index].playing;
            } else {
                //先关闭之前的
                if (this.banner_last_player_id !== '') {
                    console.log("关闭", this.banner_last_player_id);
                    var last_video = document.getElementById(this.banner_last_player_id);
                    this.villageList[this.banner_last_player_index].playing = false;
                    last_video.pause();
                }
                this.villageList[index].playing = true;
                video.play();
                console.log("播放2", index);
            }
            this.banner_last_player_id = player_id;
            this.banner_last_player_index = index;
            console.log("banner_last_player_index===", this.banner_last_player_index)
        },
        handleVideoEnded(index) {
            this.list[index].playing = false;
        },
        handleCurrentChange(val) {
            this.page = val;
            this.last_player_id = '';
            this.last_player_index = -1;
            this.postVideoList();

            window.scrollTo(0, 500);
        },
        handlePlay(index, player_id) {

            //先关闭banner的播放
            if (this.banner_last_player_index != -1) {
                console.log("关闭banner播放");
                if (this.villageList[this.banner_last_player_index].playing) {
                    this.villageList[this.banner_last_player_index].playing = false;
                    var bannerVideo = document.getElementById(this.banner_last_player_id);
                    bannerVideo.pause();
                }
            }




            console.log(this.last_player_id, player_id);

            var video = document.getElementById(player_id);
            if (this.last_player_id == player_id) {
                if (this.list[index].playing) {
                    video.pause();
                    console.log("关闭", this.last_player_id);
                } else {
                    video.play();
                    console.log("播放1", index);
                }
                this.list[index].playing = !this.list[index].playing;
            } else {
                //先关闭之前的
                if (this.last_player_id !== '') {
                    console.log("关闭", this.last_player_id);
                    var last_video = document.getElementById(this.last_player_id);
                    this.list[this.last_player_index].playing = false;
                    last_video.pause();
                }
                this.list[index].playing = true;
                video.play();
                console.log("播放2", index);
            }
            this.last_player_id = player_id;
            this.last_player_index = index;
            // console.log("====",this.list);
        },
        postVillageList() {

            var that = this;
            // 发送POST请求
            axios.post('https://v-video.56yidi.com/h5/v1/video/banner', {})
                .then(response => {

                    var arr = response.data.data;
                    for (var index = 0; index < arr.length; index++) {
                        var obj = arr[index];
                        obj.player_id = "banner_player_" + index;
                        obj.playing = false;

                    }
                    that.villageList = response.data.data;
                    console.log("villageList===", response.data.data, that.villageList);
                })
                .catch(error => {
                    console.log("0====", error)
                })
        },
        postVideoList() {
            var that = this;
            // 发送POST请求
            axios.post('https://v-video.56yidi.com/h5/v1/video/list', { page: this.page })
                .then(response => {
                    that.last_page = response.data.data.last_page;
                    var arr = response.data.data.data;
                    for (var index = 0; index < arr.length; index++) {
                        var obj = arr[index];
                        obj.player_id = "player_" + index;
                        obj.playing = false;

                    }
                    that.list = arr;
                    console.log(that.list);
                })
                .catch(error => {
                    console.log("0====", error)
                })
        }
    },
    data() {
        return {
            page: 1,
            player: null,
            villageList: [],
            last_page: 1,
            list: [],
            last_player_id: '',
            last_player_index: -1,
            banner_last_player_id: '',
            banner_last_player_index: -1,
            bannerAuto: true
        }
    }
};
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

}


.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.el-pagination {
    text-align: center;
}

/* .el-carousel__item {
    height: 500px;
} */

.video-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
}

.item-li {
    position: relative;
    /* overflow: hidden; */
}


.play-icon {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
}

.play-div {
    position: relative;
}

.video-content {
    font-size: 1em;
    color: white;
    /* height: 40px; */
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    line-height: 20px;
    text-align: left;
    /* margin: 10px 5px; */
    padding: 10px 5px 0 5px;
}

.home-banner {
    /* height: 500px; */
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
    border-radius: 10px;
    /* padding-top: 120px; */
}

.banner {
    width: 100%;
    /* 图片宽度，避免拉伸变形 */
    /* height: 500px; */
    /* 图片高度 */

}

.logo-child {
    /* width: 410px;
    height: 100px; */
}

.waterfall {
    padding: 15px;
    margin-top: 20px;
    column-count: 4;
    /* 将容器分成 3 列 */
    column-gap: 20px;
    /* 列与列之间的间距 */
}

.item {
    break-inside: avoid;
    /* 防止子元素在容器边界处被切断 */
    margin-bottom: 30px;
    /* 子元素之间的间距 */
    padding: 15px;
    background-color: rgb(37, 38, 50);
    color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.home-container {
    width: 100%;
    height: 100%;
}

.complaintTelephone {
    text-align: center;
    font-size: 12px;
    padding-bottom: 10px;
}

.gwab {
    text-align: center;
    font-size: 12px;
    color: #939599;
}

.gwab a {
    color: #939599;
    ;
}

.gongan-image {
    width: 12px;
}


.product-dsc {
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    padding: 50px 0 0px 0;
}

.product-text {
    font-size: 20px;
    color: #999;
    font-weight: 100;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.product-title {
    font-size: 30px;
    line-height: 20px;
    color: white;
    font-weight: 100;
    text-align: center;
    margin: 80px 0 40px 0;
}


.product-img {
    margin-left: 1vw;
    width: 18vw;
    height: 40vw;
}
.product-scan{
    width: 200px;
    height: 200px;
    margin-left: calc(50vw - 100px);
}

</style>
        
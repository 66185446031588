<template>
    <div class="about-container">
        <div class="about-banner">
            <img class="about-banner-img" src="../images/zhenxing.jpeg" alt="">
        </div>
        <div class="content">
            <p> 乡村逢集短视频平台是基于当前互联网时代的新型乡村信息传播模式，与传统的宣传推广策略相比，具有更加直观、生动、具体的表现形式；以农户为基础，乡村为单位，通过平台将乡村特产、特色、景观和文化进行集中展示，从而达到本土产品走出去，外部客人走进来的良好效果，吸引更多的客人前来游览、消费和投资，从而带动乡村经济的发展。
            </p>
            <p> 通过展示乡村的历史文化、传统技艺、民俗风情等方面的短视频，可以让更多的人了解和认识乡村的文化底蕴和传统魅力，促进乡村文化的传承和发展，同时提升乡村的知名度和美誉度。
            </p>
            <p> 乡村逢集短视频平台是一种全新的、高效的乡村信息传播平台，可以有效地推动乡村旅游、特色农产品销售、宣传乡村传统文化等方面的发展，促进乡村经济的繁荣和社会的进步，是乡村振兴的一大助力。</p>
        </div>
        <footer id="end">
            <div class="container">
                <div style="display: flex;">
                    <div class="flex contact">
                        <!-- <div >联系我们</div> -->
                        <div> <i class="iconfont icon-service-phone"></i> 全国热线：0551-62769502</div>
                    </div>
                    <div class=" contact">
                        <div><i style="margin-right: 2px;"
                                class="iconfont icon-dizhi"></i>地&ensp;&ensp;&ensp;&ensp;址：合肥市包河区青年电商园6栋301</div>
                    </div>
                    <div class=" contact">
                        <!-- <router-link to="/privacy"> -->
                        <div @click="yinsiEvent"> <i class="iconfont icon-protection"></i> 
                             隐私政策</div>

                        <!-- </router-link>/ -->
                    </div>
                </div>
                <div v-if="showPop">
                    <div class="pop-xieyi" v-html="htmlContent"></div>
                    <img @click="closeEvent" class="close" src="../images/叉.png" alt="">
                </div>


                <span class="company">
                    版权所有 © 2023 All Rights Reserved 安徽极智云网络科技有限公司 版权所有ICP证 <a href="https://beian.miit.gov.cn/"
                        target="_blank" style="color: #b6b7b9;">皖B2-20170077</a>
                </span>
            </div>
        </footer>
    </div>
</template>
        
<script>
// import axios from "axios";
export default {
    name: "AboutComponet",
    data() {
        return {
            searchStr: "",
            htmlContent: "",
            showPop: false
        };
    },
    methods: {
        closeEvent() {
            this.showPop = false;
        },
        yinsiEvent() {
           let page= this.$router.resolve({ name: 'YinsiComponet' })
           window.open(page.href,'_blank')
            // this.showPop = true
            // console.log('====2');
            // var that = this;
            // // 发送POST请求
            // axios.post("https://v-video.56yidi.com/v1/common/news", {
            //     type: 9,
            // })
            //     .then(response => {
            //         if (response.data.code == 1) {
            //             that.htmlContent = response.data.data.content;
            //         }

            //         console.log(response.data.data.content);
            //     })
            //     .catch(error => {
            //         console.log("0====", error);
            //         // this.$message.error(error);
            //     });
        },
    },
};
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about-container {
    display: flex;
    flex-direction: column;
    /* height:calc(100vh - 150px); */
    padding-top: 150px;
}

.about-banner {}

.about-banner-img {
    width: 90vw;
    margin: 0 5vw;
    border-radius: 10px;
}

.content {
    padding: 20px 50px;
    flex-grow: 1;
    /* color: rgb(230, 230, 230); */
    font-size: 1.3em;
    line-height: 2em;
    font-family: 'KaiTi_GB2312', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

p {
    text-indent: 2em;
    margin-top: 15px;
}

.flex {
    display: flex;
    flex-direction: column;
}

.contact {
    margin-top: 20px;
    padding-left: 20px;
}

.pop-xieyi {
    position: absolute;
    width: calc(100% - 40px);
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    z-index: 1;
    padding: 20px;
    margin-top: 100px;
}

.close {
    position: fixed;
    right: 20px;
    top: 120px;
    width: 30px;
    height: 30px;
    z-index: 2;
}

button {
    border: none;
    /* 去掉边框 */
    color: white;
    /* 按钮字体颜色 */
    box-shadow: none;
    /* 去掉阴影 */
    -webkit-box-shadow: none;
}
.xieyi {
    font-size: 12px;
    color: white;

}
</style>
        
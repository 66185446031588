<template>
    <div class="village-container" ref="scrollContainer">
        <div style="height: 120px;"></div>
        <ul class="item" v-for="(item, index) in villageList" :key="index">
            <li class="item-li " @click="GoVillageDetail(index)">
                <!-- <el-image  class="video-img" :src="item.video_image" fit="cover"></el-image> -->
                <img class="video-img" :src="item.video_image" alt="">
                <h2 class="item-name">{{ item.name }}</h2>
                <p class="item-desc">{{ item.desc }}</p>
            </li>
        </ul>
        <footer id="end">
            <div class="container">
                <span class="company">
                    版权所有 © 2023 All Rights Reserved 安徽极智云网络科技有限公司 版权所有ICP证 <a href="https://beian.miit.gov.cn/"
                        target="_blank" style="color: #b6b7b9;">皖B2-20170077</a>
                </span>
            </div>
        </footer>
    </div>
</template>
        
<script>
import axios from "axios";
import { getScrollHeight, getScrollTop, getWindowHeight } from "../js/screen";


export default {
    name: "VillageComponet",
    data() {
        return {
            villageList: [],
            page: 1,
            total: 10,
            hasMore: true
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        this.loadData();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll, false);
    },
    methods: {
        GoVillageDetail(index) {
            console.log("index==", index);
            var village_id = this.villageList[index].id;
            this.$router.push({ name: 'VillageVideo', query: { id: village_id, item: this.villageList[index] } })
        },
        handleScroll() {

            let that = this;
            if (getScrollTop() + getWindowHeight() >= getScrollHeight()) {
                if (that.hasMore) {

                    that.page = that.page + 1;
                    that.loadData();
                } else {
                    console.log("到底了");
                }
            }
        },
        loadData() {
            var that = this;
            // 发送POST请求
            axios.post('https://v-video.56yidi.com/h5/v1/village/list', { page: that.page })
                .then(response => {
                    that.total = response.data.data.total;
                    if (that.page >= response.data.data.last_page) {
                        that.hasMore = false;
                    } else {
                        that.hasMore = true;
                    }

                    if (that.page == 1) {
                        that.villageList = response.data.data.data;
                    } else {
                        for (var obj of response.data.data.data) {
                            that.villageList.push(obj);
                        }
                    }

                    console.log(response.data.data.data);

                })
                .catch(error => {
                    console.log("0====", error)
                })
        },
        handleCurrentChange(val) {
            this.page = val;
            console.log("page---", this.page);
            this.postVideoList();

        },
    },
};
</script>
        
<style scoped>
.item-li {
    position: relative;
}

.video-img {
    width: 80vw;
    margin:  50px 10vw;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    /* height: auto; */
}

.item-name {
    position: absolute;
    top: 45%;
    left: 15vw;
    width: 70vw;
    font-size: 2em;
}

.item-desc {
    position: absolute;
    top: 55%;
    left: 15vw;
    color: white;
    font-size:1em;
    width: 70vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;

}
</style>
        